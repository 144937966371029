export default function useRestartTest() {
    const auth = useAuth()
    const route = useRoute()!

    const confirmation = useConfirmation({
        title: 'Restart test',
        description: 'Are you sure? You will lose all your progress.',
        confirmLabel: 'Restart',
    })

    async function restart() {
        confirmation.confirmable(async () => {
            await auth.restartTest()

            navigateTo({
                name: 'test-chapter-question',
                force: true,
                replace: true,
                // route.name === 'test-intro' ? 'test-chapter' : 'test-intro',
            })
        })
    }

    return {
        restart,
        confirmation,
    }
}
